var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.isChangeBot ? _c('div', {
    staticClass: "bot-detailed",
    on: {
      "click": function click($event) {
        _vm.managerConfigurationMenu = false;
      }
    }
  }, [_c('section', {
    staticStyle: {
      "min-width": "100px"
    },
    on: {
      "mouseenter": function mouseenter($event) {
        return _vm.positionConfigurationMenu(true);
      },
      "mouseleave": function mouseleave($event) {
        return _vm.positionConfigurationMenu(false);
      }
    }
  }, [_c('p-navigation-drawer', {
    attrs: {
      "items-menu": _vm.menuItems,
      "props-logo": _vm.propsLogo,
      "page-initial": _vm.pageInitial,
      "color": _vm.$vuetify.theme.currentTheme['bgMenu']
    },
    scopedSlots: _vm._u([{
      key: "custom",
      fn: function fn() {
        return [_c('v-list', {
          attrs: {
            "nav": "",
            "dense": ""
          }
        }, [_c('v-list-item', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip.right",
            value: {
              content: _vm.$t('navigationDrawer.tooltip.clickNotifications'),
              offset: 2
            },
            expression: "{ content: $t('navigationDrawer.tooltip.clickNotifications'), offset: 2 }",
            modifiers: {
              "right": true
            }
          }],
          staticStyle: {
            "position": "relative"
          },
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              _vm.managerNotificationMenu = true;
            }
          }
        }, [_c('v-list-item-icon', {
          staticClass: "ml-5"
        }, [_c('v-img', {
          staticStyle: {
            "max-width": "22px",
            "height": "22px"
          },
          attrs: {
            "src": "/img/icon-notification.svg"
          }
        })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.$t('navigationDrawer.notifications')) + " ")])], 1)], 1), _c('v-list-item', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip.top",
            value: {
              content: _vm.$t('navigationDrawer.tooltip.clickConfigurations'),
              offset: 2
            },
            expression: "{ content: $t('navigationDrawer.tooltip.clickConfigurations'), offset: 2 }",
            modifiers: {
              "top": true
            }
          }],
          staticStyle: {
            "position": "relative"
          },
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              return _vm.toogleConfigMenu($event);
            }
          }
        }, [_c('v-list-item-icon', {
          staticClass: "ml-5"
        }, [_c('v-img', {
          staticStyle: {
            "max-width": "22px",
            "height": "22px"
          },
          attrs: {
            "src": "/img/icon-configuration-v2.svg"
          }
        })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.$t('navigationDrawer.configurations')) + " ")])], 1)], 1)], 1), _c('div', {
          staticClass: "d-flex justify-center mt-9",
          staticStyle: {
            "width": "100%"
          }
        }, [_c('logout')], 1)];
      },
      proxy: true
    }], null, false, 2494411948)
  })], 1), _vm.managerConfigurationMenu ? _c('configurationMenu', {
    attrs: {
      "openclose": _vm.openMenu
    },
    on: {
      "closeConfigurationMenu": function closeConfigurationMenu() {
        return _vm.toogleConfigMenu();
      }
    }
  }) : _vm._e(), _vm.managerBotsMenu ? _c('botsMenu', {
    on: {
      "callback": function callback(event) {
        return _vm.managerBotsMenu = event;
      }
    }
  }) : _vm._e(), _vm.managerNotificationMenu ? _c('notificationsMenu', {
    on: {
      "callback": function callback(event) {
        return _vm.managerNotificationMenu = event;
      }
    }
  }) : _vm._e(), _c('trainingAndPublication'), _c('router-view'), _vm.$route.name === 'BotDetailed' ? _c('emptyView', {
    attrs: {
      "title": _vm.$t('navigationDrawer.botDetailedTitle'),
      "icon-path": "/img/icon-bot.svg",
      "message": _vm.$t('navigationDrawer.botDetailedMessage')
    }
  }) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }