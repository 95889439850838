import { mapGetters, mapMutations, mapActions } from 'vuex'
import Const from '@/helper/const'
import {
  SvgLoader,
  Logout,
  TrainingAndPublication,
  BotsMenu,
  EmptyView,
  ConfigurationMenu,
  NotificationsMenu
} from '@/components'

export default {
  data () {
    return {
      privateRoute: Const.PRIVATE_ROUTE,
      managerBotsMenu: false,
      managerNotificationMenu: false,
      managerConfigurationMenu: false,
      isChangeBot: false,
      menuItems: [
        {
          title: this.$t('navigationDrawer.titleIntent'),
          imagePath: '/img/icon-intentions.svg',
          imageColor: '#fff',
          pathName: `${Const.PRIVATE_ROUTE}/bot-detailed/intentions`,
          textTooltip: this.$t('navigationDrawer.tooltip.textIntent'),
          id: 'Intentions'
        },
        {
          title: this.$t('navigationDrawer.titleEntities'),
          imagePath: '/img/icon-entities.svg',
          imageColor: '#fff',
          pathName: `${Const.PRIVATE_ROUTE}/bot-detailed/entities`,
          textTooltip: this.$t('navigationDrawer.tooltip.textEntities'),
          id: 'Entities'
        },
        {
          title: this.$t('navigationDrawer.titleResources'),
          imagePath: '/img/icon-resources.svg',
          imageColor: '#fff',
          pathName: `${Const.PRIVATE_ROUTE}/bot-detailed/resources`,
          textTooltip: this.$t('navigationDrawer.tooltip.textResources'),
          id: 'Resources'
        },
        {
          title: this.$t('navigationDrawer.titleChannel'),
          imagePath: '/img/icon-channels.svg',
          imageColor: '#fff',
          pathName: `${Const.PRIVATE_ROUTE}/bot-detailed/channels`,
          id: 'Channels'
        },
        {
          title: this.$t('navigationDrawer.titleReports'),
          imagePath: '/img/icon-reports.svg',
          imageColor: '#fff',
          pathName: `${Const.PRIVATE_ROUTE}/bot-detailed/reports`,
          id: 'Reports'
        },
        {
          title: this.$t('navigationDrawer.titleAnalyze'),
          imagePath: '/img/icon-analytic.svg',
          imageColor: '#fff',
          pathName: `${Const.PRIVATE_ROUTE}/bot-detailed/analytic`,
          id: 'Analytic'
        },
        {
          title: this.$t('navigationDrawer.titleBroadcast'),
          imagePath: '/img/icon-broadcast.svg',
          imageColor: '#fff',
          pathName: `${Const.PRIVATE_ROUTE}/bot-detailed/broadcast`,
          id: 'Broadcast'
        },
        {
          title: this.$t('navigationDrawer.titleActiveTraining'),
          imagePath: '/img/icon-active-training.svg',
          imageColor: '#fff',
          pathName: `${Const.PRIVATE_ROUTE}/bot-detailed/active-training`,
          id: 'ActiveTraining'
        }
        // {
        //   title: 'Operações',
        //   imagePath: '/img/icon-operations.svg',
        //   imageColor: '#fff',
        //   pathName: 'Operations'
        // }
      ],
      propsLogo: {},
      openMenu: true,
      pageInitial: this.$t('navigationDrawer.titleIntent')
    }
  },
  components: {
    Logout,
    SvgLoader,
    TrainingAndPublication,
    BotsMenu,
    EmptyView,
    ConfigurationMenu,
    NotificationsMenu
  },
  computed: {
    ...mapGetters([
      'getterSelectedBot',
      'getterSelectedCompany',
      'getterNotifications'
    ])
  },
  created () {
    this.setBot()
  },
  mounted () {
    this.$eventBus.$on('botChange', () => {
      this.isChangeBot = true

      setTimeout(() => {
        this.isChangeBot = false
        this.setBot()

        this.managerBotsMenu = false
      })
    })

    this.clearBotDetailedData()

    this.actionGetNotifications({ page: 1, size: 15 })
  },
  beforeDestroy () {
    window.sessionStorage.removeItem('current')
    this.clearBotDetailedData()

    this.$eventBus.$off('botChange')
  },
  methods: {
    ...mapMutations([
      'CLEAR_INTENTIONS_STORE',
      'CLEAR_CATEGORIES_INTENTION_STORE',
      'CLEAR_CATEGORIES_RESOURCE_STORE',
      'CLEAR_RESOURCES_STORE',
      'SET_EMPTY_NOTIFICATIONS',
      'CLEAR_DISABIGUATION_SCORE_INTENTIONS',
      'CLEAR_ENTITIES_STORE'
    ]),
    ...mapActions([
      'actionGetNotifications'
    ]),
    clearBotDetailedData () {
      this.CLEAR_DISABIGUATION_SCORE_INTENTIONS()
      this.CLEAR_INTENTIONS_STORE()
      this.CLEAR_RESOURCES_STORE()
      this.CLEAR_CATEGORIES_INTENTION_STORE()
      this.CLEAR_CATEGORIES_RESOURCE_STORE()
      this.CLEAR_ENTITIES_STORE()
      this.SET_EMPTY_NOTIFICATIONS()
    },
    toogleConfigMenu (e) {
      if (e) {
        e.stopPropagation()
      } else {
        this.pageInitial = ''
      }

      this.managerConfigurationMenu = !this.managerConfigurationMenu
    },
    viewDashboard () {
      this.$router.push({ name: 'Dashboard' })
    },
    positionConfigurationMenu (openClose) {
      this.openMenu = openClose
    },
    setBot () {
      this.propsLogo = {
        isImg: !!this.getterSelectedBot.logo,
        src: this.getterSelectedBot.logo || '/img/icon-bot.svg',
        version: this.getterSelectedBot.botVersions && this.getterSelectedBot.botVersions[0].name ? this.getterSelectedBot.botVersions[0].name : '',
        title: this.getterSelectedBot.name,
        isAction: true,
        action: () => {
          this.managerBotsMenu = true
        }
      }
    }
  }
}
